import { useState } from "react";

import AssessmentApi from "@/api/assessment-api";
import AssessorForm from "@/components/forms/assessor";
import AssessmentStepForm from "@/components/forms/assessment-step";
import Confirm from "@/components/common/confirm";
import ContextMenu, { type ContextMenuOption } from "@/components/context-menu";
import UserAvatar from "@/components/user-avatar";
import { ACTION } from "@/components/assessment-grid/constants";

type Step = {
  id: string;
  assignment_id: string;
  title: string;
  ordering: number;
};

type Parent = {
  assignment_id: string;
  assessors: any[];
};

interface AssessmentStepUtilProps {
  parent: Parent;
  step: Step;
  active: boolean;
  is_first: boolean;
  is_last: boolean;
  dispatch: React.Dispatch<any>;
}

export default function AssessmentStepUtil({
  parent,
  step,
  active,
  is_first,
  is_last,
  dispatch,
}: AssessmentStepUtilProps) {
  const [shared_abort_controller, _] = useState(new AbortController());
  const _addAssessor = (data) => {
    dispatch({ type: ACTION.update_assessors, payload: data });
  };

  const _removeAssessor = ({ email }) => {
    AssessmentApi.removeAssessmentUser(
      email,
      parent.assignment_id,
      step.id,
      shared_abort_controller.signal,
    ).then(({ error }) => {
      if (error) {
        console.error(error);
      } else {
        dispatch({
          type: ACTION.remove_assessor,
          payload: { email, assessment_step_id: step.id },
        });
      }
    });
  };

  const _moveLeft = () => {
    AssessmentApi.changeAssessmentStepOrdering(
      step.id,
      false,
      shared_abort_controller.signal,
    ).then(({ data, error }) => _handleMoved({ data, error }));
  };

  const _moveRight = () => {
    AssessmentApi.changeAssessmentStepOrdering(
      step.id,
      true,
      shared_abort_controller.signal,
    ).then(({ data, error }) => _handleMoved({ data, error }));
  };

  const _handleMoved = ({ data, error }) => {
    if (error) {
      console.error(error);
    } else {
      dispatch({ type: ACTION.add_assessment_steps, payload: data });
    }
  };

  const _remove = () => {
    AssessmentApi.removeAssessmentStep(
      step.id,
      shared_abort_controller.signal,
    ).then(({ error }) => {
      if (error) {
        console.error(error);
      } else {
        dispatch({ type: ACTION.remove_assessment_step, payload: step.id });
      }
    });
  };

  const _updateAssessmentStep = (name) => {
    AssessmentApi.updateAssessmentStep(
      {
        step_id: step.id,
        assignment_id: parent.assignment_id,
        title: name,
      },
      shared_abort_controller.signal,
    ).then(({ error, data }) => {
      if (error) {
        console.error(error);
      } else {
        dispatch({ type: ACTION.update_assessment_steps, payload: data });
      }
    });
  };

  const context_options: ContextMenuOption[] = [
    {
      icon: "person_add",
      label: "Add assessor",
      form: (
        <AssessorForm
          assignment_id={parent.assignment_id}
          assessment_step_id={step.id}
          callbackFn={_addAssessor}
        />
      ),
    },
    {
      icon: "edit",
      label: "Change step name",
      form: (
        <AssessmentStepForm
          name={step.title}
          callbackFn={_updateAssessmentStep}
        />
      ),
    },
  ];
  if (!is_first) {
    context_options.push({
      icon: "arrow_back_ios_new",
      label: "Move left",
      onClick: _moveLeft,
    });
  }
  if (!is_last) {
    context_options.push({
      icon: "arrow_forward_ios",
      label: "Move right",
      onClick: _moveRight,
    });
  }
  context_options.push({
    icon: "delete",
    label: "Delete",
    onClick: _remove,
  });

  return (
    <div
      key={step.id}
      className="flex h-[5rem] w-[12rem] flex-col items-center text-center"
    >
      <div className="flex items-center font-bold">
        {step.title}
        {active && (
          <span className="ml-2">
            <ContextMenu options={context_options} />
          </span>
        )}
      </div>

      <div className="group mt-2 flex flex-row">
        {parent.assessors
          ?.filter((assessor) => step.id === assessor.assessment_step_id)
          .map((assessor) => {
            return (
              <div
                key={assessor.email}
                className="-ml-2 text-[10px] transition-margin first:ml-0 group-hover:ml-1"
              >
                <UserAvatar user={assessor} />
                {active && (
                  <Confirm
                    body={`Removing ${assessor.email}`}
                    fn_args={{
                      email: assessor.email,
                    }}
                    confirmFn={_removeAssessor}
                  >
                    <span
                      className="material-symbols-rounded invisible cursor-pointer text-slate-600 hover:text-almost-white group-hover:visible"
                      data-email={assessor.email}
                    >
                      delete
                    </span>
                  </Confirm>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}
