import { useState } from "react";

import ApiErrorComponent from "@/components/common/api-error";
import Button from "@/components/common/input/button";
import CommentApi from "@/api/comment-api";
import Textarea from "@/components/common/input/textarea";
import { useAuth } from "@/hooks/auth";

export default function CandidateCommentPostBox({ candidate, postCallback }) {
  const { user } = useAuth();
  const [comment, setComment] = useState("");
  const [post_error, setPostError] = useState(null);

  const _formSubmit = (e) => {
    e.preventDefault();
    _onSubmit();
  };

  function _onSubmit() {
    if (!comment) return;
    CommentApi.postCandidateComment({
      candidate_id: candidate.id,
      data: comment,
    }).then((result) => {
      if (result.error) {
        console.error(result.error);
        setPostError(result.error);
      } else {
        postCallback(result.data);
        setPostError(null);
        setComment("");
      }
    });
  }

  return (
    <div className="w-full">
      <form className="flex w-full flex-col" onSubmit={_formSubmit}>
        <div className="flex flex-col">
          <Textarea
            id="post-box"
            label="Leave a comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            rows={4}
            placeholder="Write your comment here"
            maxLength={1000}
            required
          />
          <span className="-mt-1 mb-4 text-xs text-gray-500">
            {comment.length} / 1000 characters
          </span>
        </div>
        <Button type="submit">Post</Button>
      </form>
      {post_error && <ApiErrorComponent error={post_error} />}
    </div>
  );
}
