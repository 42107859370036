export const ACTION = {
  add_assessment_step: "add-assessment-step",
  add_assessment_steps: "add-assessment-steps",
  add_assessors: "add-assessors",
  add_assignment_competencies: "add-assignment-competencies",
  remove_assessment_step: "remove-assessment-step",
  remove_assessor: "remove-assessor",
  remove_assignment_competency: "remove-assignment-competency",
  toggle_edit: "toggle-edit",
  update_assessment_steps: "update-assessment-steps",
  update_assessors: "update-assessors",
  update_assignment_competencies: "update-assignment-competencies",
};
