import { request, handleResponse } from "@/hooks/request";
import type { Assignment } from "@/types/assignment";

const AssignmentApi = {
  fetchAll: (
    abort_signal: AbortSignal,
  ): Promise<{ data: Assignment[]; error: Error | null }> => {
    return request("/api/assignment", {
      signal: abort_signal,
    }).then(handleResponse);
  },
  fetchOne: (id: string, abort_signal: AbortSignal) => {
    return request(`/api/assignment/${id}`, {
      signal: abort_signal,
    }).then(handleResponse);
  },
  update: (updated_assignment: any, abort_signal: AbortSignal) => {
    return request("/api/assignment", {
      method: "put",
      signal: abort_signal,
      body: JSON.stringify(updated_assignment),
    }).then(handleResponse);
  },
  rateCandidate: (
    candidate_id: string,
    score: number,
    abort_signal: AbortSignal,
  ) => {
    return request("/api/candidate/rate-candidate", {
      method: "post",
      signal: abort_signal,
      body: JSON.stringify({ candidate_id, score }),
    }).then(handleResponse);
  },
  getAssignmentUsers: (assignment_id: string, abort_signal: AbortSignal) => {
    return request(`/api/assignment/${assignment_id}/user`, {
      signal: abort_signal,
    }).then(handleResponse);
  },
  addAssignmentUser: (
    email: string,
    assignment_id: string,
    role: string,
    abort_signal: AbortSignal,
  ) => {
    return request("/api/assignment/user", {
      method: "post",
      signal: abort_signal,
      body: JSON.stringify({
        email,
        assignment_id,
        role,
      }),
    }).then(handleResponse);
  },
  changeAssignmentUser: (
    email: string,
    assignment_id: string,
    role: string,
    abort_signal: AbortSignal,
  ) => {
    return request("/api/assignment/user", {
      method: "put",
      signal: abort_signal,
      body: JSON.stringify({
        email,
        assignment_id,
        role,
      }),
    }).then(handleResponse);
  },
  removeAssignmentUser: (
    email: string,
    assignment_id: string,
    abort_signal: AbortSignal,
  ) => {
    return request("/api/assignment/user", {
      method: "delete",
      signal: abort_signal,
      body: JSON.stringify({
        email,
        assignment_id,
      }),
    }).then(handleResponse);
  },
  getAssignmentCompetencies: (
    assignment_id: string,
    abort_signal: AbortSignal,
  ) => {
    return request(`/api/assignment/${assignment_id}/competency`, {
      signal: abort_signal,
    }).then(handleResponse);
  },
  getAssignmentCompetency: (
    assignment_id: string,
    competency_id: string,
    abort_signal: AbortSignal,
  ) => {
    return request(
      `/api/assignment/${assignment_id}/competency/${competency_id}`,
      {
        signal: abort_signal,
      },
    ).then(handleResponse);
  },
  addCompetencyToAssignment: (
    assignment_id: string,
    competency_id: string,
    override: any,
    abort_signal: AbortSignal,
  ) => {
    return request("/api/assignment/competency", {
      method: "post",
      signal: abort_signal,
      body: JSON.stringify({
        assignment_id,
        competency_id,
        override,
      }),
    }).then(handleResponse);
  },
  addCompetenciesToAssignment: (
    assignment_id: string,
    competency_ids: string[],
    abort_signal: AbortSignal,
  ) => {
    return request("/api/assignment/competencies", {
      method: "post",
      signal: abort_signal,
      body: JSON.stringify({
        assignment_id,
        competency_ids,
      }),
    }).then(handleResponse);
  },
  removeCompetencyFromAssignment: (
    assignment_id: string,
    competency_id: string,
    abort_signal: AbortSignal,
  ) => {
    return request("/api/assignment/competency", {
      method: "delete",
      signal: abort_signal,
      body: JSON.stringify({
        assignment_id,
        competency_id,
      }),
    }).then(handleResponse);
  },
  updateSharingSettings: (
    assignment_id: string,
    data: any,
    abort_signal: AbortSignal,
  ) => {
    return request("/api/assignment/sharing-settings", {
      method: "post",
      signal: abort_signal,
      body: JSON.stringify({ assignment_id, items: data }),
    }).then(handleResponse);
  },
  syncCandidates: ({
    assignment_id,
    abort_signal,
  }: {
    assignment_id: string;
    abort_signal: AbortSignal;
  }) => {
    return request(`/api/assignment/${assignment_id}/sync`, {
      method: "post",
      signal: abort_signal,
    }).then(handleResponse);
  },
};

export default AssignmentApi;
