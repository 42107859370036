import { useEffect, useReducer, type MouseEvent } from "react";
import clsx from "clsx";

import type { ApiResponse } from "@/types/api";
import AssessmentApi from "@/api/assessment-api";
import Button from "@/components/common/input/button";
import { nl2br } from "@/helpers";

interface Competency {
  id: string;
  name: string;
  description?: string;
  area: string | null;
  area_slug: string | null;
}

interface CompetencyState {
  competencies: Competency[];
  selected: string[];
  filter: string | null;
  areas: [string, string][];
}

type CompetencyAction =
  | { type: "set-competencies"; payload: Competency[] }
  | { type: "set-filter"; payload: string }
  | { type: "update-areas"; payload: Competency[] }
  | { type: "toggle-marker"; payload: string };

interface CompetencyBrowserProps {
  callbackFn?: (selected: string[]) => void;
  toggleFn?: () => void;
}

const reducer = (
  state: CompetencyState,
  action: CompetencyAction,
): CompetencyState => {
  switch (action.type) {
    case "set-competencies":
      return { ...state, competencies: action.payload };
    case "set-filter": {
      const new_filter =
        action.payload !== state.filter ? action.payload : null;
      return { ...state, filter: new_filter };
    }
    case "update-areas": {
      const new_areas = state.areas;
      action.payload.forEach((comp) => {
        if (comp.area === null || comp.area_slug === null) return;
        if (
          !state.areas.some((area) => {
            const [area_slug, area_name] = area;
            return area_slug === comp.area_slug && area_name === comp.area;
          })
        ) {
          new_areas.push([comp.area_slug, comp.area]);
        }
      });
      return { ...state, areas: new_areas };
    }
    case "toggle-marker": {
      let new_selected: string[] = [];
      if (state.selected.includes(action.payload)) {
        new_selected = state.selected.filter(
          (comp_id) => comp_id !== action.payload,
        );
      } else {
        new_selected = [...state.selected, action.payload];
      }

      return { ...state, selected: new_selected };
    }
  }
};

export default function CompetencyBrowser({
  callbackFn,
  toggleFn,
}: CompetencyBrowserProps) {
  const [state, dispatch] = useReducer<typeof reducer>(reducer, {
    competencies: [],
    selected: [],
    filter: null,
    areas: [
      ["leading-oneself", "Leading oneself"],
      ["shaping-the-future", "Shaping the future"],
      ["moving-things-forward", "Moving things forward"],
      ["interacting-with-others", "Interacting with others"],
    ],
  });

  useEffect(() => {
    const abort_controller = new AbortController();
    AssessmentApi.getCompetencies(abort_controller.signal).then(
      (result: ApiResponse<Competency[]>) => {
        if (result.error) {
          console.error(result.error);
          return;
        }
        if (result.data) {
          dispatch({ type: "set-competencies", payload: result.data });
          dispatch({ type: "update-areas", payload: result.data });
        }
      },
    );
    return () => {
      abort_controller.abort();
    };
  }, []);

  const _toggleMarker = (comp: Competency): void => {
    dispatch({ type: "toggle-marker", payload: comp.id });
  };

  const _handleSubmit = (): void => {
    callbackFn && callbackFn(state.selected);
    toggleFn && toggleFn();
  };

  const _handleCancel = (ev: MouseEvent<HTMLButtonElement>): void => {
    ev.preventDefault();
    toggleFn && toggleFn();
  };

  const _getFilterBtn = (selected: boolean, disabled = false): string => {
    return clsx(
      "border-1",
      "rounded-md",
      "border",
      "p-2",
      !disabled && "cursor-pointer",
      disabled && "cursor-not-allowed",
      disabled && "text-gray-700",
      !disabled && "active:translate-y-px",
      "select-none",
      disabled && "border-gray-700",
      !selected && !disabled && "hover:border-slate-800",
      !selected && !disabled && "border-slate-700",
      selected && !disabled && "hover:border-future-purple-300",
      selected && !disabled && "border-future-purple",
    );
  };

  return (
    <div className="mt-4 w-[64rem] rounded-md bg-slate-800 p-8">
      <div className="mb-4 flex items-center gap-4">
        <Button type="submit" onClick={_handleSubmit}>
          Submit
        </Button>
        <Button onClick={_handleCancel}>Cancel</Button>
      </div>
      <div className="mb-4 flex flex-wrap gap-2 text-slate-300">
        <span className="p-2 font-bold">Filter</span>
        <span
          className={_getFilterBtn(
            state.filter === "selected",
            state.selected.length === 0,
          )}
          onClick={
            state.selected.length > 0
              ? () => dispatch({ type: "set-filter", payload: "selected" })
              : undefined
          }
        >
          Selected
        </span>
        {state.areas.map((area) => {
          const [area_slug, area_name] = area;
          return (
            <span
              key={area_slug}
              className={_getFilterBtn(state.filter === area_slug)}
              onClick={() =>
                dispatch({ type: "set-filter", payload: area_slug })
              }
            >
              {area_name}
            </span>
          );
        })}
      </div>
      <div className="flex flex-wrap items-start gap-8">
        {state.competencies
          .filter((comp): boolean => {
            if (!state.filter) return true;
            return (
              comp.area_slug === state.filter ||
              (state.filter === "selected" && state.selected.includes(comp.id))
            );
          })
          .map((comp) => {
            const competenceboxclass = clsx(
              "w-[29rem]",
              "cursor-pointer",
              "rounded-md",
              "hover:bg-slate-900",
              "p-4",
              "border",
              "grow-0",
              !state.selected.includes(comp.id) && "hover:border-slate-900",
              !state.selected.includes(comp.id) && "border-transparent",
              state.selected.includes(comp.id) && "hover:border-green-600",
              state.selected.includes(comp.id) && "border-green-500",
            );
            return (
              <div
                key={comp.id}
                className={competenceboxclass}
                onClick={() => _toggleMarker(comp)}
              >
                <p className="mb-2 font-bold text-gray-300">{comp.name}</p>
                {comp.description && (
                  <p className="text-sm">{nl2br(comp.description)}</p>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}
