import { useEffect, useRef, useState, cloneElement } from "react";
import { createPortal } from "react-dom";
import type { ClassValue } from "class-variance-authority/types";
import { cn } from "@/helpers";

interface ModalProps {
  children: [React.ReactElement, React.ReactElement];
  onClose?: () => void;
  onOpen?: () => void;
}

interface EnhancedChildProps {
  onClick: () => void;
  className?: ClassValue[];
}

function Modal({ children, onClose, onOpen }: ModalProps) {
  const [is_active, setActive] = useState(false);

  const modal_element = document.getElementById("modal") as HTMLElement;

  const _ref = useRef<HTMLDivElement>(null);

  const toggleFn = () => {
    if (is_active) {
      onClose && onClose();
    } else {
      onOpen && onOpen();
    }
    setActive(!is_active);
  };

  const modalHandleClickOutside = (event: PointerEvent): void => {
    if (_ref.current && !_ref.current.contains(event.target as Node)) {
      setActive(false);
      onClose && onClose();
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    document.addEventListener("pointerdown", modalHandleClickOutside, true);
    if (is_active) {
      document.body.classList.add("overflow-hidden");
      modal_element.classList.add("overflow-y-auto");
      modal_element.classList.add("modal-is-active");
    }
    return () => {
      document.removeEventListener(
        "pointerdown",
        modalHandleClickOutside,
        true,
      );
      document.body.classList.remove("overflow-hidden");
      modal_element.classList.remove("modal-is-active");
      modal_element.classList.remove("overflow-y-auto");
    };
  }, [is_active]);

  const [first_child, second_child] = children;

  return (
    <>
      {
        cloneElement(first_child, {
          onClick: toggleFn,
        }) as unknown as EnhancedChildProps
      }
      {is_active &&
        createPortal(
          <div className="modal-window overscroll-contain" ref={_ref}>
            {cloneElement(second_child, {
              is_active,
              toggleFn,
            })}
          </div>,
          modal_element,
          "modal",
        )}
    </>
  );
}

export default Modal;
