import LinkedinIcon from "@/components/common/icons/linkedin-icon.tsx";
import { cn } from "@/helpers";
import type { CandidateData } from "@/types/candidate";

interface InfoBoxProps {
  candidate: CandidateData;
}

export default function InfoBox({ candidate }: InfoBoxProps) {
  if (
    !(
      candidate.url ||
      candidate.email ||
      candidate.birthyear ||
      candidate.salary ||
      candidate.phone
    )
  ) {
    return null;
  }
  const infodatarowclass = cn("grid grid-cols-[3rem_1fr] items-center h-8");
  const infoboxclass = cn("text-base max-w-[16rem]");
  return (
    <div className={infoboxclass}>
      {candidate.url && (
        <div className={infodatarowclass}>
          <div title="URL">
            {candidate.url.toLowerCase().includes("linkedin") ? (
              <LinkedinIcon style="w-5 h-5" />
            ) : (
              <span className="material-symbols-rounded notranslate">
                language
              </span>
            )}
          </div>
          <div>
            <a
              href={candidate.url}
              title="Link"
              target="_blank"
              rel="noreferrer"
            >
              {candidate.url.toLowerCase().includes("linkedin")
                ? "LinkedIn"
                : "Website"}
            </a>
          </div>
        </div>
      )}
      {candidate.email && (
        <div className={infodatarowclass}>
          <div className="material-symbols-rounded notranslate" title="Email">
            mail
          </div>
          <div>
            <a
              href={`mailto:${candidate.email}`}
              className="flex items-center gap-1"
              title="Email address"
            >
              {candidate.email}
            </a>
          </div>
        </div>
      )}
      {candidate.birthyear && (
        <div className={infodatarowclass}>
          <div
            className="material-symbols-rounded notranslate"
            title="Birthyear"
          >
            cake
          </div>
          <div>{candidate.birthyear}</div>
        </div>
      )}
      {candidate.phone && (
        <div className={infodatarowclass}>
          <div
            className="material-symbols-rounded notranslate"
            title="Phone number"
          >
            call
          </div>
          <div>
            <a href={`tel:${candidate.phone}`}>{candidate.phone}</a>
          </div>
        </div>
      )}
    </div>
  );
}
