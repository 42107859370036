import { useState } from "react";

import AssignmentApi from "@/api/assignment-api";
import CompetencyForm from "@/components/forms/competency";
import CompetencyOverrideViewer from "@/components/competency-override-viewer";
import Confirm from "@/components/common/confirm";
import ContextMenu, { type ContextMenuOption } from "@/components/context-menu";
import { ACTION } from "@/components/assessment-grid/constants";
import type { Assignment } from "@/types/assignment";
import type { Competency } from "@/types/competency";
import { nl2br } from "@/helpers";

interface CompetencyListProps {
  assignment: Assignment;
  competencies: Competency[];
  dispatch: React.Dispatch<any>;
  edit_mode: boolean;
}

export default function CompetencyList({
  assignment,
  competencies,
  dispatch,
  edit_mode,
}: CompetencyListProps) {
  const [shared_abort_controller, _] = useState(new AbortController());

  const _updateCompetencies = (data) => {
    dispatch({ type: ACTION.update_assignment_competencies, payload: data });
  };

  const _removeCompetency = ({ assignment_id, competency_id }) => {
    AssignmentApi.removeCompetencyFromAssignment(
      assignment_id,
      competency_id,
      shared_abort_controller.signal,
    ).then(({ error }) => {
      if (error) {
        console.error(error);
      } else {
        dispatch({
          type: ACTION.remove_assignment_competency,
          payload: competency_id,
        });
      }
    });
  };

  return (
    <div className="bg-bg-primary flex flex-none flex-col gap-4">
      {competencies.map((comp) => {
        const context_options: ContextMenuOption[] = [
          {
            icon: "settings",
            label: "Modify",
            form: (
              <CompetencyForm
                id={comp.id}
                assignment_id={assignment.id}
                callbackFn={_updateCompetencies}
              />
            ),
          },
          {
            icon: "play_circle",
            label: "View",
            form: (
              <CompetencyOverrideViewer
                competency_id={comp.id}
                assignment_id={assignment.id}
              />
            ),
          },
          {
            icon: "delete",
            label: "Remove",
            confirm: (
              <Confirm
                body={"Remove competency"}
                fn_args={{
                  assignment_id: assignment.id,
                  competency_id: comp.id,
                }}
                confirmFn={_removeCompetency}
              />
            ),
          },
        ];

        return (
          <div key={comp.id} className="h-[5rem] overflow-hidden">
            <div className="flex items-center font-bold">
              {edit_mode && (
                <span className="mr-2">
                  <ContextMenu options={context_options} right />
                </span>
              )}
              {comp.is_overridden && (
                <span
                  className="material-symbols-rounded notranslate pr-1 text-sm text-green-400"
                  title="This competency is specific for this assignment"
                >
                  stat_0
                </span>
              )}
              {comp.name}
            </div>
            <p className="text-[12px] text-slate-400">
              {nl2br(comp.description)}
            </p>
          </div>
        );
      })}
    </div>
  );
}
