import { useEffect, useRef, useState } from "react";
import clsx from "clsx";

import Modal from "./common/modal";

const MENU_WIDTH = 200;

export type ContextMenuOption = {
  icon: string;
  label: string;
  onClick?: () => void;
  form?: JSX.Element;
  confirm?: {
    type: any;
    props: any;
  };
};

interface ContextMenuProps {
  options: ContextMenuOption[];
  right?: boolean;
}

export default function ContextMenu({ options, right }: ContextMenuProps) {
  const _menuwrapperref = useRef<HTMLDivElement>(null);

  const [menu, setMenu] = useState({ is_open: false, left: 0, top: 0 });

  const outerrowclass = clsx("group", "w-full", "cursor-pointer");
  const innerrowclass = clsx(
    "flex",
    "items-center",
    "group-hover:text-slate-600",
    "hover:bg-slate-300",
    "text-sm",
    "px-2",
    "py-1",
  );
  const iconclass = clsx("material-symbols-rounded", "mr-2");
  const menuclass = clsx(
    "absolute",
    "w-44",
    "flex",
    "flex-col",
    "z-50",
    "bg-slate-800",
    "rounded-md",
    {
      hidden: !menu.is_open,
    },
  );

  const _handleKeydown = (ev) => {
    if (menu.is_open && ev.key === "Escape") {
      _toggleOpen();
      ev.preventDefault();
    }
  };

  const _handleClickOutside = (ev) => {
    if (
      _menuwrapperref.current &&
      !_menuwrapperref.current.contains(ev.target)
    ) {
      ev.preventDefault();
      _toggleOpen();
    }
  };

  useEffect(() => {
    if (menu.is_open) {
      document.addEventListener("keydown", _handleKeydown);
      document.addEventListener("click", _handleClickOutside);
    }
    const removeListener = () => {
      document.removeEventListener("keydown", _handleKeydown);
      document.removeEventListener("click", _handleClickOutside);
    };
    return removeListener;
  }, [menu.is_open]);

  const _toggleOpen = () => {
    if (!_menuwrapperref.current) return;
    const { width, height } = _menuwrapperref.current.getBoundingClientRect();
    setMenu((state) => {
      return {
        is_open: !state.is_open,
        left: right ? "0" : `${width - MENU_WIDTH + 24}px`,
        top: `${height}px`,
      };
    });
  };

  const _handleMenuClick = () => {
    setMenu((state) => {
      return { ...state, is_open: false };
    });
  };

  if (options.length === 0) return null;

  return (
    <div className="relative h-[24px] font-normal" ref={_menuwrapperref}>
      <span
        className={clsx(
          "material-symbols-rounded",
          "z-0",
          "cursor-pointer",
          "select-none",
          "rounded-full",
          "active:bg-slate-700",
          "hover:text-slate-600",
        )}
        onClick={_toggleOpen}
      >
        more_vert
      </span>
      <div
        className={menuclass}
        style={{ left: menu.left, top: menu.top }}
        onClick={_handleMenuClick}
      >
        {options.map((option, idx) => {
          let inner_row = null;
          let innerrowclass_override = innerrowclass;
          let outerrowclass_override = outerrowclass;
          if (idx === 0) {
            // the first row
            innerrowclass_override = clsx(innerrowclass, "rounded-t-md");
            outerrowclass_override = clsx(outerrowclass, "rounded-t-md");
          }
          if (idx === options.length - 1) {
            // the last row
            innerrowclass_override = clsx(innerrowclass, "rounded-b-md");
            outerrowclass_override = clsx(outerrowclass, "rounded-b-md");
          }
          if (Object.keys(option).includes("form")) {
            inner_row = (
              <Modal>
                <div className={innerrowclass_override}>
                  <span className={iconclass}>{option.icon}</span>
                  <span>{option.label}</span>
                </div>
                {option.form}
              </Modal>
            );
          } else if (Object.keys(option).includes("confirm")) {
            inner_row = (
              <option.confirm.type {...option.confirm.props}>
                <span className={innerrowclass_override}>
                  <span className={iconclass}>{option.icon}</span>
                  <span>{option.label}</span>
                </span>
              </option.confirm.type>
            );
          } else {
            inner_row = (
              <div className={innerrowclass_override} onClick={option.onClick}>
                <span className={iconclass}>{option.icon}</span>
                <span>{option.label}</span>
              </div>
            );
          }
          return (
            <div key={option.icon} className={outerrowclass_override}>
              {inner_row}
            </div>
          );
        })}
      </div>
    </div>
  );
}
