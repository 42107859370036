import { cn } from "@/helpers";

interface ControlProps {
  scrollIndex: number;
  handleScroll: (direction: string) => void;
  isAtEnd: boolean;
}

export const ScrollControls = ({
  scrollIndex,
  handleScroll,
  isAtEnd,
}: ControlProps) => {
  const buttonclass =
    "material-symbols-rounded notranslate cursor-pointer p-2 select-none active:translate-y-0.5 disabled:active:translate-y-0";
  return (
    <div className="flex h-[5rem] items-center justify-center gap-4">
      <button
        disabled={scrollIndex === 0}
        className={cn(buttonclass, scrollIndex === 0 && "text-slate-600")}
        onClick={() => handleScroll("left")}
      >
        chevron_left
      </button>
      <button
        disabled={isAtEnd}
        className={cn(buttonclass, isAtEnd && "text-slate-600")}
        onClick={() => handleScroll("right")}
      >
        chevron_right
      </button>
    </div>
  );
};

export const OverlayScrollControls = ({
  scrollIndex,
  handleScroll,
  isAtEnd,
}: ControlProps) => {
  const wrapperclass =
    "absolute top-0 flex h-full w-8 items-center justify-center";
  const buttonclass =
    "material-symbols-rounded notranslate cursor-pointer px-1 py-3 select-none active:translate-y-0.5 disabled:active:translate-y-0";
  return (
    <>
      <div className={cn(wrapperclass, "left-[20rem]")}>
        <button
          disabled={scrollIndex === 0}
          className={cn(buttonclass, scrollIndex === 0 && "text-slate-600")}
          onClick={() => handleScroll("left")}
        >
          chevron_left
        </button>
      </div>
      <div className={cn(wrapperclass, "right-0")}>
        <button
          disabled={isAtEnd}
          className={cn(buttonclass, isAtEnd && "text-slate-600")}
          onClick={() => handleScroll("right")}
        >
          chevron_right
        </button>
      </div>
    </>
  );
};
