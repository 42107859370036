import { useState, useReducer } from "react";
import {
  useActionData,
  useLoaderData,
  useParams,
  redirect,
  Form,
  type LoaderFunctionArgs,
} from "react-router";
import clsx from "clsx";

import AssessmentApi from "@/api/assessment-api";
import ApiErrorComponent from "@/components/common/api-error";
import CandidateApi from "@/api/candidate-api";
import Button from "@/components/common/input/button";
import CandidateInfo from "@/components/candidate-info";
import Textarea from "@/components/common/input/textarea";

const reducer = (state, action) => {
  switch (action.type) {
    case "set-assessment":
      return { ...state, assessment: action.payload };
    case "set-score": {
      const new_assessment_state = {
        scores: { ...state.assessment.scores, ...action.payload },
        notes: { ...state.assessment.notes },
        competencies: [...state.assessment.competencies],
      };
      return { ...state, assessment: { ...new_assessment_state } };
    }
  }
};

export default function AssessCandidate() {
  const { assessment, candidate, error } = useLoaderData();
  const { assignment_id } = useParams();
  const submit_error = useActionData();
  const [state, dispatch] = useReducer(reducer, { assessment });

  if (error) {
    return <ApiErrorComponent error={error} />;
  }

  async function scoreCompetency(competency_id, score) {
    dispatch({ type: "set-score", payload: { [competency_id]: [{ score }] } });
  }

  return (
    <div className="flex flex-col gap-6 pb-12">
      <CandidateInfo candidate={candidate} />
      {state.assessment.competencies.length === 0 && (
        <div className="mx-auto w-96 p-8 text-center">
          No competencies to assess.
        </div>
      )}
      <Form
        action={`/assignment/${assignment_id}/assess/${candidate.id}`}
        method="post"
      >
        {state.assessment.competencies.map((comp) => {
          return (
            <div key={comp.id} className="mx-auto mb-8 w-[60rem]">
              <AssessCompetency
                competency={comp}
                score={
                  state.assessment.scores[comp.id] &&
                  Number.isInteger(state.assessment.scores[comp.id][0].score)
                    ? state.assessment.scores[comp.id][0].score
                    : ""
                }
                note={
                  state.assessment.notes[comp.id]
                    ? state.assessment.notes[comp.id][0].note
                    : null
                }
                scoreFn={scoreCompetency}
              />
            </div>
          );
        })}
        <div className="mx-auto w-[60rem]">
          {submit_error && (
            <div className="mb-8 rounded-md border-2 border-red-500 bg-red-200 p-8 text-red-900">
              An error occured, please check all fields again.
              <br /> It's ok to select <em>Did not assess</em>.
            </div>
          )}
          {state.assessment.competencies.length > 0 && (
            <Button type="submit">Save assessment</Button>
          )}
        </div>
      </Form>
    </div>
  );
}

function AssessCompetency({ competency: comp, score, note, scoreFn }) {
  const [is_open_indicators, setOpenIndicators] = useState(false);

  const middledivclass = clsx(
    "flex items-center justify-between rounded-t-md bg-slate-800 p-4",
    {
      "rounded-b-md": !is_open_indicators,
    },
  );

  function handleScore(ev, competency_id, score) {
    ev.preventDefault();
    scoreFn(competency_id, score);
  }

  return (
    <div key={comp.id}>
      <input type="hidden" name={`score:${comp.id}`} value={score} />
      <div className={middledivclass}>
        <div>
          <h2 className="mb-4 bg-slate-800 text-2xl font-bold">{comp.name}</h2>
          <p className="max-w-[40rem] text-slate-300">{comp.description}</p>
        </div>
        <div
          className="material-symbols-rounded cursor-pointer p-4 select-none hover:text-slate-600"
          onClick={() => setOpenIndicators(!is_open_indicators)}
        >
          {is_open_indicators ? "expand_less" : "expand_more"}
        </div>
      </div>
      {is_open_indicators && (
        <>
          <div className="space-y-8 bg-slate-900 px-4 py-4">
            <p className="font-bold text-slate-100">Questions</p>
            {comp.questions_leader &&
              comp.questions_leader.map((question, idx) => {
                return (
                  // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                  <p key={idx} className="max-w-[40rem]">
                    {question}
                  </p>
                );
              })}
          </div>
          <div className="grid grid-cols-2">
            <div className="rounded-bl-md bg-green-200 px-4 pt-4 pb-2">
              <p className="pb-2 font-bold text-slate-600">
                Positive indicators
              </p>
              <ul className="ml-4 list-disc text-neutral-900">
                {comp.positive_indicators &&
                  comp.positive_indicators.map((indicator, idx) => {
                    return (
                      // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                      <li key={idx} className="mb-2">
                        {indicator}
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="rounded-br-md bg-red-200 px-4 pt-4 pb-2">
              <p className="pb-2 font-bold text-slate-600">
                Negative indicators
              </p>
              <ul className="ml-4 list-disc text-neutral-900">
                {comp.negative_indicators &&
                  comp.negative_indicators.map((indicator, idx) => {
                    return (
                      // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                      <li key={idx} className="mb-2">
                        {indicator}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </>
      )}

      <div className="m-4 flex justify-center gap-4 rounded-b-md">
        <Button
          onClick={(ev) => handleScore(ev, comp.id, 1)}
          selected={score === 1 ? "accept" : null}
        >
          1
        </Button>
        <Button
          onClick={(ev) => handleScore(ev, comp.id, 2)}
          selected={score === 2 ? "accept" : null}
        >
          2
        </Button>
        <Button
          onClick={(ev) => handleScore(ev, comp.id, 3)}
          selected={score === 3 ? "accept" : null}
        >
          3
        </Button>
        <Button
          onClick={(ev) => handleScore(ev, comp.id, 4)}
          selected={score === 4 ? "accept" : null}
        >
          4
        </Button>
        <Button
          onClick={(ev) => handleScore(ev, comp.id, 5)}
          selected={score === 5 ? "accept" : null}
        >
          5
        </Button>
        <Button
          onClick={(ev) => handleScore(ev, comp.id, 0)}
          selected={score === 0 ? "default" : null}
        >
          Did not assess
        </Button>
      </div>
      <Textarea
        className="mx-auto min-h-[80px] max-w-[36rem]"
        defaultValue={note}
        placeholder="Add notes"
        name={`note:${comp.id}`}
      />
    </div>
  );
}

export async function loader({
  request,
  params: { assignment_id, candidate_id },
}: LoaderFunctionArgs) {
  if (!candidate_id || !assignment_id) {
    throw new Error("Missing IDs");
  }
  const { data: assessment, error: assessment_error } =
    await AssessmentApi.getUserAssessment(candidate_id, request.signal);
  const {
    data: { candidate },
    error: candidate_error,
  } = await CandidateApi.fetchOne(candidate_id, assignment_id, request.signal);

  return {
    candidate,
    assessment,
    error: assessment_error
      ? assessment_error
      : candidate_error
        ? candidate_error
        : null,
  };
}

export async function action({ params, request }) {
  const { candidate_id, assignment_id } = params;
  const formdata = Object.fromEntries(await request.formData());
  const candidate_assessment = {};
  Object.keys(formdata).map((formkey) => {
    const [category, competency_id] = formkey.split(":");
    if (category === "note" && formdata[formkey]) {
      candidate_assessment[competency_id] = {
        ...candidate_assessment[competency_id],
        note: formdata[formkey],
      };
    } else if (category === "score" && formdata[formkey]) {
      candidate_assessment[competency_id] = {
        ...candidate_assessment[competency_id],
        score: formdata[formkey],
      };
    }
  });
  const competencies = [];
  for (const [competency_id, data] of Object.entries(candidate_assessment)) {
    competencies.push({ competency_id, ...data });
  }
  const { data: assessment, error } = await AssessmentApi.scoreCandidate(
    candidate_id,
    competencies,
  );
  if (error) {
    return { error };
  }
  return redirect(`/assignment/${assignment_id}`);
}
