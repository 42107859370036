import {
  useNavigate,
  useRouteLoaderData,
  useRevalidator,
  Link,
} from "react-router";
import clsx from "clsx";

import AssessmentApi from "@/api/assessment-api";
import AssessmentButton from "@/components/assessment-button";
import LinkedinIcon from "@/components/common/icons/linkedin-icon";
import ScoreDisplay from "@/components/score-display";
import { Badge } from "@/components/badge";
import { useAuth } from "@/hooks/auth";
import StarRating from "@/components/common/star-rating";
import type { Scorecard } from "@/types/scorecard";
import type { AssignmentLoaderData } from "@/types/assignment";
import type { User } from "@/types/user";

export type CandidateInListProps = {
  candidate: any;
  admin: boolean;
  scorecards: Scorecard;
  all_competencies_count: number;
  user_competencies_count: number;
};

export default function CandidateInList({
  candidate,
  admin,
  scorecards,
  all_competencies_count,
  user_competencies_count,
}: CandidateInListProps) {
  const { user }: { user: User } = useAuth();
  const { assignment } = useRouteLoaderData(
    "single-assignment",
  ) as AssignmentLoaderData;
  const revalidator = useRevalidator();
  const navigate = useNavigate();

  const full_access = admin || assignment.user_role === "full_access";

  const hide_comments =
    assignment.user_role === "assessor" &&
    !assignment?.settings?.sharing_settings?.comments;

  const toggleAssessmentCandidate = () => {
    const abort_controller = new AbortController();
    if (candidate.assess) {
      AssessmentApi.removeAssessmentCandidate(
        candidate.id,
        abort_controller.signal,
      ).then(({ error }) => {
        if (!error) {
          revalidator.revalidate();
        }
      });
    } else {
      AssessmentApi.addAssessmentCandidate(
        candidate.id,
        abort_controller.signal,
      ).then(({ error }) => {
        if (!error) {
          revalidator.revalidate();
        }
      });
    }
  };

  const candidate_url: string = `/assignment/${assignment.id}/candidate/${candidate.id}`;

  const candidateclass = clsx(
    "p-4",
    "col-span-11",
    "items-center",
    "grid",
    "md:grid-cols-12",
    "md:grid-rows-[minmax(0,max-content)_1fr]",
    "grid-cols-6",
    "gap-x-2",
  );

  let score: number | null = null;
  const my_assessments = [];
  let assessments_done = 0;

  if (scorecards) {
    const scores_count = Object.entries(scorecards.scores).flatMap(
      ([_, scores]) => scores,
    ).length;
    const total_score = Object.entries(scorecards.scores)
      .flatMap(([_, scores]) => scores)
      .reduce((acc, curr) => acc + curr.score, 0);
    score = scores_count > 0 ? total_score / scores_count : 0;
    Object.entries(scorecards.scores).forEach(([_, userscores]) => {
      userscores.forEach((userscore) => {
        assessments_done += 1;
        if (userscore.user.id === user.id) {
          my_assessments.push(userscore);
        }
      });
    });
  }

  const candidate_ratings_total = candidate.ratings.reduce(
    (acc, curr) => acc + curr.score,
    0,
  );
  const candidate_rating = candidate_ratings_total / candidate.ratings.length;

  const should_assess =
    my_assessments.length === 0 && user_competencies_count > 0;

  const should_edit =
    (my_assessments.length > 0 &&
      my_assessments.length < user_competencies_count &&
      user_competencies_count > 0) ||
    my_assessments.length >= user_competencies_count;

  return (
    <div className={candidateclass}>
      <div className="col-span-5">
        <div className="overflow-hidden font-bold text-ellipsis">
          <Link to={candidate_url} className="flex items-center">
            {candidate.name}
          </Link>
        </div>
      </div>
      <div className="col-span-5 row-start-2">
        <div className="text-sm">
          {candidate.title && (
            <span className="text-gray-400">{candidate.title}</span>
          )}
        </div>
        <div className="text-sm">{candidate.organization || ""}</div>
      </div>

      <div className="col-span-3 col-start-6" title="Most recent action">
        {candidate.most_recent_action && (
          <Badge>{candidate.most_recent_action}</Badge>
        )}
      </div>

      <div className="col-span-3 row-start-2 flex w-full items-center gap-4">
        {candidate.url && (
          <a
            href={candidate.url}
            className="flex items-center gap-1"
            title="Link"
            target="_blank"
            rel="noreferrer"
          >
            {candidate.url.toLowerCase().includes("linkedin") ? (
              <LinkedinIcon style="w-5 h-5" />
            ) : (
              <span className="material-symbols-rounded notranslate">
                language
              </span>
            )}
          </a>
        )}
        {!hide_comments && (
          <div
            className="relative flex cursor-pointer items-center gap-1"
            onClick={() => navigate(`${candidate_url}#add-comment`)}
          >
            <span className="material-symbols-rounded notranslate mt-1">
              comment
            </span>
            <span className="text-sm">{candidate?.comment_count || 0}</span>
          </div>
        )}
        {full_access && (
          <div className="col-span-1 flex items-center justify-center">
            <StarRating score={candidate_rating} candidate_id={candidate.id} />
          </div>
        )}
        {admin && (
          <div
            onClick={toggleAssessmentCandidate}
            title={
              candidate.assess ? "Remove from assessment" : "Add to assessment"
            }
            className={clsx(
              "material-symbols-rounded notranslate col-span-1 flex cursor-pointer justify-end select-none",
              { "opacity-40": !candidate.assess },
            )}
          >
            {candidate.assess ? "verified_user" : "shield"}
          </div>
        )}
      </div>
      <div className="col-span-3 row-span-2 flex items-center gap-4">
        {scorecards && (
          <>
            <ScoreDisplay
              score={score}
              counts={{
                all: all_competencies_count,
                done: assessments_done,
                user: user_competencies_count,
                user_done: my_assessments.length,
              }}
            />
            {should_edit && (
              <Link to={`/assignment/${assignment.id}/assess/${candidate.id}`}>
                <AssessmentButton>Edit assessment</AssessmentButton>
              </Link>
            )}
            {should_assess && (
              <Link to={`/assignment/${assignment.id}/assess/${candidate.id}`}>
                <AssessmentButton>Add assessment</AssessmentButton>
              </Link>
            )}
          </>
        )}
      </div>
    </div>
  );
}
