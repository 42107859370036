import clsx from "clsx";

import ErrorMessage from "@/components/error-message";

interface InputProps {
  label?: string;
  register?: any;
  error?: string;
  no_margin?: boolean;
  register_options?: any;
  id?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  extra_classes?: string;
  required?: boolean;
  value?: string | number | readonly string[];
  placeholder?: string;
}

const Input = ({
  label,
  register,
  error,
  no_margin,
  register_options = {},
  ...props
}: InputProps) => {
  if (register && !props.id) {
    console.error("Input with 'register' must also have 'id'");
  }

  const inputclass = clsx(
    "w-full",
    "bg-midnight-blue",
    "p-2",
    "rounded",
    "border-2",
    "active:border-gray-600",
    "transition-[outline-offset]",
    "duration-150",
    "ease-in-out",
    "outline-offset-0",
    "focus-visible:outline-offset-2",
    {
      "mb-5": !no_margin,
      block: label,
      "border-gray-700": !error,
      "border-red-400": error,
      "text-slate-400": props.disabled,
      "cursor-not-allowed": props.disabled,
    },
    props.extra_classes,
  );

  if (label) {
    return (
      <label htmlFor={props.id} className="block w-full">
        <span className="inline-block leading-relaxed">
          {label}{" "}
          {props.required && (
            <span title="Required field" className="text-red-400">
              *
            </span>
          )}
        </span>
        <input
          name={props.id}
          className={inputclass}
          onChange={props.onChange}
          onKeyDown={props.onKeyDown}
          {...(register ? register(props.id, register_options) : [])}
          {...props}
        />
        <ErrorMessage message={error} />
      </label>
    );
  }
  return (
    <div>
      <input
        name={props.id}
        className={inputclass}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        {...(register ? register(props.id) : [])}
        {...props}
      />
      <ErrorMessage message={error} />
    </div>
  );
};

Input.displayName = "Input";

export default Input;
