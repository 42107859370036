import { request, handleResponse } from "@/hooks/request";

const CommentApi = {
  fetchCandidateComments: (candidate_id: string, abort_signal: AbortSignal) => {
    return request(`/api/comment/candidate/${candidate_id}`, {
      signal: abort_signal,
    }).then(handleResponse);
  },
  postCandidateComment: (comment: any, abort_signal: AbortSignal) => {
    return request("/api/comment/candidate", {
      method: "post",
      signal: abort_signal,
      body: JSON.stringify(comment),
    }).then(handleResponse);
  },
  deleteCandidateComment: (comment_id: string, abort_signal: AbortSignal) => {
    return request(`/api/comment/${comment_id}`, {
      method: "delete",
      signal: abort_signal,
    }).then(handleResponse);
  },
};

export default CommentApi;
