import { request, handleResponse } from "@/hooks/request";
import type { Assignment } from "@/types/assignment";
import type { Candidate } from "@/types/candidate";

const CandidateApi = {
  fetchOne: (
    candidate_id: string,
    assignment_id: string,
    abort_signal: AbortSignal,
  ) => {
    return request(
      `/api/candidate/${candidate_id}/assignment/${assignment_id}`,
      {
        signal: abort_signal,
      },
    ).then((response) =>
      handleResponse<{ candidate: Candidate; assignment: Assignment }>(
        response,
      ),
    );
  },
  scoreCandidate: (
    assignment_id: string,
    assessment_id: string,
    candidate_id: string,
    competency_id: string,
    score: number,
    abort_signal: AbortSignal,
  ) => {
    return request("/api/candidate/assessment", {
      method: "post",
      signal: abort_signal,
      body: JSON.stringify({
        assignment_id,
        candidate_id,
        assessment_id,
        score,
        competency_id,
      }),
    }).then(handleResponse);
  },
};

export default CandidateApi;
