import { AUTH_COOKIE_NAME, taco_cookie_ctx } from "@/auth-cookie";
import ApiError from "@/models/ApiError";

interface RequestHeaders {
  "content-type": string;
  accept: string;
  Authorization?: string;
  [key: string]: string | undefined;
}

const default_headers: RequestHeaders = {
  "content-type": "application/json",
  accept: "application/json",
};

interface ResponseData {
  json: object;
  status: number;
  is_ok: boolean;
}

export const request = async (
  url: string,
  options,
  include_default_headers = true,
): Promise<ResponseData> => {
  let final_options = {
    ...options,
    headers: {
      ...(include_default_headers ? default_headers : options.headers),
    },
  };

  const jwt = taco_cookie_ctx.get(AUTH_COOKIE_NAME);
  if (jwt !== undefined) {
    final_options = {
      ...final_options,
      headers: {
        ...final_options.headers,
        Authorization: `Bearer ${jwt}`,
      },
    };
  }

  return fetch(url, final_options).then(
    async (response): Promise<ResponseData> => {
      let response_body: object;
      try {
        response_body = await response.json();
      } catch (e) {
        response_body = {};
      }

      return {
        json: response_body,
        status: response.status,
        is_ok: response.ok,
      };
    },
  );
};

interface UseRequestProps {
  abort_controller: AbortController;
}

export const useRequest = ({ abort_controller }: UseRequestProps) => {
  const requestFn = (
    url: string,
    options,
    include_default_headers = true,
  ): Promise<ResponseData> => {
    const final_options = {
      signal: abort_controller.signal,
      ...options,
    };
    return request(url, final_options, include_default_headers).catch(
      (err: Error) => {
        if (err.name === "AbortError") {
          return {
            status: 0,
            is_ok: false,
            json: { message: "Request aborted" },
          };
        } else {
          throw err;
        }
      },
    );
  };

  return requestFn;
};

export interface HandleResponseReturn<T> {
  data: T | null;
  error: ApiError | null;
}

export function handleResponse<T>(
  response: ResponseData,
): HandleResponseReturn<T> {
  if (response.is_ok) {
    return { data: response.json as T, error: null };
  }
  return { data: null, error: new ApiError(response) };
}
