import { cn } from "@/helpers";

interface AssessmentStepIconProps {
  step_id: string;
  active: boolean;
  competency_id: string;
  checked: boolean;
  addFn: (e: React.MouseEvent<HTMLSpanElement>) => void;
  removeFn: (e: React.MouseEvent<HTMLSpanElement>) => void;
}

export default function AssessmentStepIcon({
  step_id,
  active,
  competency_id,
  checked,
  addFn,
  removeFn,
}: AssessmentStepIconProps) {
  const wrapperclass = cn(
    "flex items-center justify-center",
    "h-[5rem]",
    "text-gray-700",
    active && checked && "cursor-pointer",
  );

  return (
    <div
      className={wrapperclass}
      title={active && checked ? "Click to remove" : undefined}
      data-competency-id={competency_id}
      data-process-step-id={step_id}
      onClick={active && checked ? removeFn : undefined}
    >
      {checked ? (
        <span className="material-symbols-rounded text-3xl text-green-600">
          check_circle
        </span>
      ) : active ? (
        <span
          className="material-symbols-rounded cursor-pointer cursor-pointer text-3xl hover:text-almost-white"
          title="Add to process step"
          data-competency-id={competency_id}
          data-process-step-id={step_id}
          onClick={addFn}
        >
          add
        </span>
      ) : null}
    </div>
  );
}
