import { useLayoutEffect, useReducer, useMemo, useRef, useEffect } from "react";

import Spinner from "@/components/common/spinner";
import AssessmentStepUtil from "@/components/assessment-grid/step-util";
import AssessmentStepIcon from "@/components/assessment-grid/step-icon";
import CompetencyList from "@/components/assessment-grid/competency-list";
import { AdminControls } from "@/components/assessment-grid/admin-controls";
import {
  OverlayScrollControls,
  ScrollControls,
} from "@/components/assessment-grid/scroll-controls";
import { useAssessmentData } from "@/components/assessment-grid/data";
import { usePageTitle } from "@/hooks/title";
import { hasRole } from "@/helpers";
import { useAuth } from "@/hooks/auth";
import type { Competency } from "@/types/competency";
import type { Assignment } from "@/types/assignment";

type ScrollState = {
  isOverflowing: boolean;
  scrollIndex: number;
  isAtEnd: boolean;
  position: number;
};

type ScrollAction =
  | { type: "SET_OVERFLOW"; payload: boolean }
  | {
      type: "SCROLL";
      payload: { index: number; isAtEnd: boolean; position: number };
    }
  | { type: "RESET" };

function scrollReducer(state: ScrollState, action: ScrollAction): ScrollState {
  switch (action.type) {
    case "SET_OVERFLOW":
      return { ...state, isOverflowing: action.payload };
    case "SCROLL":
      return {
        ...state,
        scrollIndex: action.payload.index,
        isAtEnd: action.payload.isAtEnd,
        position: action.payload.position,
      };
    case "RESET":
      return {
        isOverflowing: false,
        scrollIndex: 0,
        isAtEnd: false,
        position: 0,
      };
    default:
      return state;
  }
}

interface AssmentGridProps {
  assignment: Assignment;
}

export default function AssessmentGrid2({ assignment }: AssmentGridProps) {
  usePageTitle("Assessment Process");
  const { user } = useAuth();
  const _gridWrapperRef = useRef<HTMLDivElement>(null);
  const [scrollState, scrollDispatch] = useReducer(scrollReducer, {
    isOverflowing: false,
    scrollIndex: 0,
    isAtEnd: false,
    position: 0,
  });

  const {
    state,
    dispatch,
    loading,
    _addAssessmentStep,
    _addCompetencyToStep,
    _addCompetencyToAssignment,
    _removeCompetencyFromStep,
  } = useAssessmentData(assignment.id);

  const sorted_competencies = useMemo<Competency[]>(
    () =>
      [...(state.assignment_competencies || [])].sort((a, b) =>
        a.name.localeCompare(b.name),
      ),
    [state.assignment_competencies],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!_gridWrapperRef.current) return;
    const grid = _gridWrapperRef.current;
    const checkOverflow = () => {
      const isOverflowing = grid.scrollWidth > grid.clientWidth;
      scrollDispatch({ type: "SET_OVERFLOW", payload: isOverflowing });
    };
    // Check on mount
    checkOverflow();
    // Check on resize
    const resizeObserver = new ResizeObserver(checkOverflow);
    resizeObserver.observe(grid);
    return () => resizeObserver.disconnect();
  }, [loading, state.assessment_steps]);

  const sorted_steps = useMemo(
    () => [...state.assessment_steps].sort((a, b) => a.ordering - b.ordering),
    [state.assessment_steps],
  );

  useLayoutEffect(() => {
    const grid = _gridWrapperRef.current;
    if (!grid) return;
    grid.scrollTo({
      left: scrollState.position,
      behavior: "smooth",
    });
  }, [scrollState.position]);

  const _handleScroll = (direction: string) => {
    const grid = _gridWrapperRef.current;
    if (!grid) return;
    const scrollAmount = 192; // 12rem
    const scrollDirection = direction === "right" ? 1 : -1;
    const newScrollLeft = grid.scrollLeft + scrollAmount * scrollDirection;

    const maxScroll = grid.scrollWidth - grid.clientWidth;
    const newScrollIndex = Math.floor(newScrollLeft / scrollAmount);
    scrollDispatch({
      type: "SCROLL",
      payload: {
        position: newScrollLeft,
        isAtEnd: newScrollLeft >= maxScroll,
        index: newScrollIndex,
      },
    });
  };

  const empty_state =
    state.assessment_steps.length === 0 &&
    state.assignment_competencies.length === 0;

  if (loading || state === false) {
    return (
      <div className="flex h-96 justify-center">
        <Spinner large />
      </div>
    );
  }

  return (
    <>
      {hasRole(user, "admin") && (
        <AdminControls
          edit_mode={state.edit_mode}
          dispatch={dispatch}
          onAddCompetency={_addCompetencyToAssignment}
          onAddStep={_addAssessmentStep}
        />
      )}
      {empty_state && (
        <div className="flex justify-center p-4">
          Start by adding competencies and assessment steps.
        </div>
      )}
      {!empty_state && (
        <div className="mx-auto w-[60rem] min-[74rem]:w-[72rem] min-[86rem]:w-[84rem]">
          <div className="relative flex">
            <OverlayScrollControls
              scrollIndex={scrollState.scrollIndex}
              handleScroll={_handleScroll}
              isAtEnd={scrollState.isAtEnd}
            />
            <div className="w-[20rem]">
              {scrollState.isOverflowing && (
                <ScrollControls
                  scrollIndex={scrollState.scrollIndex}
                  handleScroll={_handleScroll}
                  isAtEnd={scrollState.isAtEnd}
                />
              )}
              <CompetencyList
                assignment={assignment}
                competencies={sorted_competencies}
                dispatch={dispatch}
                edit_mode={state.edit_mode}
              />
            </div>
            <div
              className="custom-scrollbar ml-8 flex w-[36rem] snap-x snap-mandatory overflow-x-auto min-[74rem]:w-[44rem] min-[86rem]:w-[60rem]"
              ref={_gridWrapperRef}
            >
              {sorted_steps.map((step, idx) => {
                const is_first = idx === 0;
                const is_last = idx === state.assessment_steps.length - 1;

                return (
                  <div
                    key={step.id}
                    className="flex-none snap-center snap-always"
                  >
                    <AssessmentStepUtil
                      parent={{
                        assignment_id: assignment.id,
                        assessors: state.assessors,
                      }}
                      step={step}
                      active={state.edit_mode}
                      is_first={is_first}
                      is_last={is_last}
                      dispatch={dispatch}
                    />
                    <div className="flex flex-col gap-4">
                      {sorted_competencies.map((comp, _) => {
                        return (
                          <AssessmentStepIcon
                            key={comp.id}
                            active={state.edit_mode}
                            step_id={step.id}
                            competency_id={comp.id}
                            checked={
                              step.competency_ids !== null &&
                              step.competency_ids.includes(comp.id)
                            }
                            addFn={_addCompetencyToStep}
                            removeFn={_removeCompetencyFromStep}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
