import { useLoaderData, redirect, type LoaderFunctionArgs } from "react-router";

import AssignmentApi from "@/api/assignment-api";
import AssignmentNavigationMenu from "@/components/assignment-navigation-menu";
import Spinner from "@/components/common/spinner";
import type { Assignment, AssignmentLoaderData } from "@/types/assignment";
import { useAuth } from "@/hooks/auth";

export async function loader({
  request,
  params: { assignment_id },
}: LoaderFunctionArgs): Promise<AssignmentLoaderData | Response> {
  if (!assignment_id) {
    throw new Error("Assignment ID is required");
  }
  const { data, error } = await AssignmentApi.fetchOne(
    assignment_id,
    request.signal,
  );
  if (error) {
    return redirect("/login");
  }
  return { assignment: data as Assignment };
}

export default function AssignmentPage() {
  const { is_loading } = useAuth();
  const { assignment } = useLoaderData() as AssignmentLoaderData;

  if (is_loading) {
    return <Spinner />;
  }

  const route_data = [
    {
      url: `/assignment/${assignment.id}`,
      title: "Candidates",
      index: true,
      roles: ["admin", "full_access", "assessor"],
      extra_match: "process-step",
    },
    {
      url: `/assignment/${assignment.id}/grid`,
      title: "Assessment Process",
      roles: ["admin", "full_access", "assessor"],
    },
    {
      url: `/assignment/${assignment.id}/users`,
      title: "Users",
      roles: ["admin", "full_access"],
    },
    {
      url: `/assignment/${assignment.id}/settings`,
      title: "Settings",
      roles: ["admin"],
    },
  ].filter((route) => {
    return route.roles.includes(assignment.user_role);
  });
  return (
    <AssignmentNavigationMenu assignment={assignment} route_data={route_data} />
  );
}
