import { useState, useEffect, useReducer, type MouseEvent } from "react";

import AssessmentApi from "@/api/assessment-api";
import AssignmentApi from "@/api/assignment-api";
import { ACTION } from "@/components/assessment-grid/constants";

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION.add_assessment_step:
      return {
        ...state,
        assessment_steps: [...state.assessment_steps, action.payload],
      };
    case ACTION.add_assessment_steps:
      return { ...state, assessment_steps: action.payload };
    case ACTION.add_assessors: {
      return { ...state, assessors: action.payload };
    }
    case ACTION.update_assessors: {
      return { ...state, assessors: [...state.assessors, action.payload] };
    }
    case ACTION.remove_assessor: {
      const { email, assessment_step_id } = action.payload;
      const new_assessors = state.assessors.filter(
        (assessor) =>
          assessor.email !== email ||
          assessor.assessment_step_id !== assessment_step_id,
      );
      return { ...state, assessors: new_assessors };
    }
    case ACTION.add_assignment_competencies:
      return { ...state, assignment_competencies: action.payload };
    case ACTION.update_assignment_competencies: {
      const new_competencies = state.assignment_competencies.filter(
        (comp) => comp.id !== action.payload.id,
      );
      new_competencies.push(action.payload);
      return { ...state, assignment_competencies: new_competencies };
    }
    case ACTION.remove_assignment_competency: {
      const new_competencies = state.assignment_competencies.filter(
        (comp) => comp.id !== action.payload,
      );
      return { ...state, assignment_competencies: new_competencies };
    }
    case ACTION.update_assessment_steps: {
      // remove the updated step
      const new_steps = state.assessment_steps.filter(
        (step) => step.id !== action.payload.id,
      );
      // add it back with new data
      new_steps.push(action.payload);
      return { ...state, assessment_steps: new_steps };
    }
    case ACTION.remove_assessment_step: {
      const new_steps = state.assessment_steps.filter(
        (as) => as.id !== action.payload,
      );
      return { ...state, assessment_steps: new_steps };
    }
    case ACTION.toggle_edit:
      return { ...state, edit_mode: !state.edit_mode };
  }
};

const initial_state = {
  assessment_steps: [],
  assignment_competencies: [],
  assessors: [],
  edit_mode: false,
};

export const useAssessmentData = (assignment_id: string) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [state, dispatch] = useReducer(reducer, initial_state);
  const abort_controller = new AbortController();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [competencies, steps, users] = await Promise.all([
          AssignmentApi.getAssignmentCompetencies(
            assignment_id,
            abort_controller.signal,
          ),
          AssessmentApi.getAssessmentSteps(
            assignment_id,
            abort_controller.signal,
          ),
          AssessmentApi.getAssessmentUsers(
            { assignment_id: assignment_id },
            abort_controller.signal,
          ),
        ]);

        dispatch({
          type: ACTION.add_assignment_competencies,
          payload: competencies.data,
        });
        dispatch({ type: ACTION.add_assessment_steps, payload: steps.data });
        dispatch({ type: ACTION.add_assessors, payload: users.data });
      } catch (err) {
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      abort_controller.abort();
    };
  }, [assignment_id]);

  const _addAssessmentStep = (title) => {
    AssessmentApi.addAssessmentStep(
      assignment_id,
      title,
      abort_controller.signal,
    ).then(({ error, data }) => {
      if (error) {
        console.error(error);
      } else {
        dispatch({ type: ACTION.add_assessment_step, payload: data });
      }
    });
  };

  const _addCompetencyToStep = (ev: MouseEvent<HTMLElement>) => {
    const competency_id = ev.currentTarget.getAttribute("data-competency-id");
    const process_step_id = ev.currentTarget.getAttribute(
      "data-process-step-id",
    );
    if (!process_step_id || !competency_id) {
      console.error("Missing data attributes");
      return;
    }
    AssessmentApi.addCompetencyToAssessmentStep(
      process_step_id,
      competency_id,
      abort_controller.signal,
    ).then(({ data, error }) => {
      if (error) {
        console.error(error);
      } else {
        dispatch({ type: ACTION.update_assessment_steps, payload: data });
      }
    });
  };

  const _addCompetencyToAssignment = (selected) => {
    AssignmentApi.addCompetenciesToAssignment(
      assignment_id,
      selected,
      abort_controller.signal,
    ).then(({ error }) => {
      if (error) {
        console.error(error);
      } else {
        // not very DRY
        AssignmentApi.getAssignmentCompetencies(
          assignment_id,
          abort_controller.signal,
        ).then(({ data }) => {
          dispatch({
            type: ACTION.add_assignment_competencies,
            payload: data,
          });
        });
      }
    });
  };

  const _removeCompetencyFromStep = (e) => {
    const competency_id = e.currentTarget.getAttribute("data-competency-id");
    const process_step_id = e.currentTarget.getAttribute(
      "data-process-step-id",
    );
    AssessmentApi.removeCompetencyFromAssessmentStep(
      process_step_id,
      competency_id,
      abort_controller.signal,
    ).then(({ data, error }) => {
      if (error) {
        console.error(error);
      } else {
        dispatch({ type: ACTION.update_assessment_steps, payload: data });
      }
    });
  };

  return {
    state,
    dispatch,
    loading,
    error,
    _addAssessmentStep,
    _addCompetencyToStep,
    _addCompetencyToAssignment,
    _removeCompetencyFromStep,
  };
};
