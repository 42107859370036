import AssessmentStepForm from "@/components/forms/assessment-step";
import Button from "@/components/common/input/button";
import CompetencyBrowser from "@/components/competency-browser";
import Modal from "@/components/common/modal";
import { ACTION } from "@/components/assessment-grid/constants";

export function AdminControls({
  edit_mode,
  dispatch,
  onAddCompetency,
  onAddStep,
}) {
  return (
    <div className="mx-auto max-w-[60rem] py-4">
      <div className="flex items-center justify-end gap-4">
        {edit_mode && (
          <>
            <Modal>
              <Button>Add competency</Button>
              <CompetencyBrowser callbackFn={onAddCompetency} />
            </Modal>
            <Modal>
              <Button>Add assessment step</Button>
              <AssessmentStepForm callbackFn={onAddStep} />
            </Modal>
          </>
        )}
        <Button primary onClick={() => dispatch({ type: ACTION.toggle_edit })}>
          {edit_mode ? "Stop editing" : "Edit"}
        </Button>
      </div>
    </div>
  );
}
