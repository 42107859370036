class ApiError {
  status: string;
  detail: string;
  constructor(error) {
    this.status = error?.status || "Unknown status";
    this.detail = error.json?.detail;
  }
}

export default ApiError;
