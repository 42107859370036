import { useState } from "react";
import { useRevalidator } from "react-router";
import clsx from "clsx";

import AssignmentApi from "@/api/assignment-api";
import Star from "@/components/star";

interface StarRatingProps {
  size?: number;
  score?: number;
  max_score?: number;
  // onScoreChanged?: (score: number) => void;
  candidate_id: string;
}

export default function StarRating({
  size = 4,
  score = 0,
  max_score = 3,
  candidate_id,
  // onScoreChanged,
}) {
  const revalidator = useRevalidator();
  const [score_under_mouse, setScoreUnderMouse] = useState(0);

  const _mouseEnter = (star_score, star_ref) => {
    star_ref.current.classList.remove("star-rating-jump");
    setScoreUnderMouse(star_score);
  };

  const _mouseLeave = () => {
    setScoreUnderMouse(0);
  };

  const _mouseDown = (star_score, star_ref) => {
    setScoreUnderMouse(0);
    star_ref.current.classList.add("star-rating-jump");
    // onScoreChanged && onScoreChanged(star_score);
    onRating(star_score);
  };

  const onRating = (score) => {
    const abort_controller = new AbortController();
    AssignmentApi.rateCandidate(
      candidate_id,
      score,
      abort_controller.signal,
    ).then(({ error }) => {
      if (error) {
        console.error(error);
      } else {
        revalidator.revalidate();
      }
    });
  };

  const stars_class = clsx("flex", "cursor-pointer");

  return (
    <div className={stars_class} onMouseLeave={_mouseLeave}>
      {[...Array(max_score)].map((_, i) => {
        return (
          <Star
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            key={i}
            size={size}
            filled={score - i}
            under_mouse_score={score_under_mouse - i}
            value={i + 1}
            mouseEnter={_mouseEnter}
            onClick={_mouseDown}
          />
        );
      })}
    </div>
  );
}
