import type { ButtonHTMLAttributes, ReactNode } from "react";
import clsx from "clsx";

import Spinner from "@/components/common/spinner";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  wide?: boolean;
  fixed?: boolean;
  is_loading?: boolean;
  primary?: boolean;
  selected?: "accept" | "default" | null;
  aux_classes?: string;
  children: ReactNode;
  disabled?: boolean;
}

const Button = ({
  wide,
  fixed,
  is_loading,
  children,
  primary,
  selected,
  aux_classes = "",
  ...props
}: ButtonProps) => {
  const shared = [
    "active:duration-0",
    "active:outline-offset-0",
    "border-2",
    "duration-150",
    "ease-out",
    "flex",
    "focus-visible:outline-offset-2",
    "font-medium",
    "font-outfit",
    "gap-2",
    "items-center",
    "justify-center",
    "leading-relaxed",
    "min-h-[2.75rem]",
    "px-4",
    "py-px",
    "rounded",
    "shadow",
    "tracking-widest",
    "transition-btn",
    "uppercase",
    "cursor-pointer",
    "select-none",
    {
      "w-full": wide,
      "w-32": fixed,
    },
  ];
  let btnclass = clsx(
    "border-future-purple-400",
    "hover:ring-4",
    "hover:ring-offset-0",
    "hover:ring-[hsl(210deg_40%_10%_/_0.25)]",
    "active:ring-0",
    !selected && "text-white",
    selected && selected === "accept" && "bg-green-600",
    selected && selected === "default" && ["text-gray-800", "bg-gray-200"],
    shared,
    {
      "cursor-not-allowed": is_loading,
      "bg-[hsl(257deg_92%_71%_/_0.5)]": primary || props?.type === "submit",
    },
  );
  if (props?.disabled || is_loading) {
    btnclass = clsx(
      "cursor-not-allowed",
      "text-gray-400",
      "border-gray-700",
      shared,
    );
  }
  if (props?.className) {
    btnclass = clsx(btnclass, props.className);
  }

  if (aux_classes) {
    btnclass = clsx(btnclass, aux_classes);
  }

  let inner_content = children;
  if (is_loading) {
    inner_content = <Spinner />;
  }

  return (
    <button className={btnclass} disabled={is_loading} type="button" {...props}>
      {inner_content}
    </button>
  );
};

export default Button;
